<template>
  <div class="card card-custom">
    <div class="card-header d-flex align-items-center justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Yangi mijoz ro'yxatga olish</h3>
      </div>
    </div>
    <div class="card-body">
      <v-form class="form">
        <!-- <div>
          <v-select
            :items="clientType"
            v-model="selected_opt"
            @change="ddd(selected_opt)"
            item-text="name"
            dense
            item-value="value"
            label="Mijoz turi"
            outlined
          ></v-select>
        </div> -->
        <div v-if="JI">
          <div style="
              border: 1px solid #333;
              border-radius: 4px;
              padding: 20px;
              margin-bottom: 20px;
            ">
            <h5 style="margin-bottom: 1.75rem">Jismoniy shaxs malumotlari</h5>
            <div>
              <v-text-field :error-messages="clientNameErrors" v-model="clientName" label="Mijoz nomi" outlined dense
                required @input="$v.clientName.$touch()" @blur="$v.clientName.$touch()"></v-text-field>
            </div>
            <div class="d-flex">
              <div class="col-6 pl-0">
                <v-select :error-messages="clientJisStatus" :items="returnClientStatus11" v-model="status11"
                  item-text="status" item-value="id" dense label="Status" outlined @input="$v.status11.$touch()"
                  @blur="$v.status11.$touch()"></v-select>
              </div>

              <div class="col-6 pr-0">
                <v-text-field :error-messages="clientPassportNumber" v-mask="'AA#######'" v-model="passportNumber"
                  placeholder="AA1234567" label="Pasport raqami" required @input="$v.passportNumber.$touch()"
                  @blur="$v.passportNumber.$touch()" outlined dense></v-text-field>
              </div>
            </div>
            <div class="d-flex">
              <div class="col-4 pl-0">
                <v-autocomplete v-model="oblast" :error-messages="oblastErrors" :items="returnOblast" item-text="name"
                  item-value="id" dense label="Oblast" outlined required return-object @input="$v.oblast.$touch()"
                  @blur="$v.oblast.$touch()"></v-autocomplete>
              </div>
              <div class="col-4">
                <v-autocomplete v-model="region" :error-messages="regionErrors" :items="returnRegions" item-text="name"
                  item-value="id" label="Region" dense outlined return-object @input="$v.region.$touch()"
                  @blur="$v.region.$touch()"></v-autocomplete>
              </div>
              <div class="col-4 pr-0">
                <v-text-field v-model="adress" label="Manzil" outlined dense></v-text-field>
              </div>
            </div>
            <div>
              <v-textarea outlined v-model="comments" label="Izoh" dense></v-textarea>
            </div>
          </div>
        </div>

        <div v-if="YU">
          <div style="
              border: 1px solid #333;
              border-radius: 4px;
              padding: 20px;
              margin-bottom: 20px;
            ">
            <h5 style="margin-bottom: 1.75rem">Yuridik shaxs malumotlari</h5>
            <div>
              <v-text-field :error-messages="clientNameErrors" v-model="clientName" label="Mijoz nomi" dense outlined
                required @input="$v.clientName.$touch()" @blur="$v.clientName.$touch()"></v-text-field>
            </div>
            <div class="d-flex">
              <div class="col-4 pl-0">
                <v-text-field :error-messages="clientYurInn" outlined v-model="inn" dense v-mask="'### ### ###'"
                  label="INN" required @input="$v.inn.$touch()" @blur="$v.inn.$touch()"></v-text-field>
              </div>
              <div class="col-4">
                <v-text-field v-model="oked" :counter="5" v-mask="'#####'" label="OKED" dense required
                  outlined></v-text-field>
              </div>
              <div class="col-4 pr-0">
                <v-text-field outlined v-model="ndsNumber" label="NDS raqami" v-mask="'###############'" required
                  :counter="15" dense></v-text-field>
              </div>
            </div>

            <h5 class="mb-5 text-dark">Bank malumotlari:</h5>
            <div class="d-flex">
              <div class="col-6 pl-0">
                <v-autocomplete :error-messages="mfoClient" v-model="mfo" :items="getMfo" dense item-text="full_name"
                  item-value="id" outlined label="Mfo" @input="$v.mfo.$touch()" @blur="$v.mfo.$touch()"
                  @keypress="isNumberKey" v-debounce:200ms="getMfoDebounce"></v-autocomplete>
              </div>
              <div class="col-6 pr-0">
                <v-text-field v-model="account" outlined dense label="Bank hisob raqami"
                  v-mask="'#### #### #### #### ####'" :error-messages="bankNumber" @input="$v.account.$touch()"
                  @blur="$v.account.$touch()"></v-text-field>
              </div>
            </div>

            <div class="d-flex">
              <div class="col-4 pl-0">
                <v-autocomplete v-model="oblast" :items="returnOblast" item-text="name" item-value="id" dense
                  return-object label="Oblast" outlined></v-autocomplete>
              </div>
              <div class="col-4">
                <v-autocomplete v-model="region" :items="returnRegions" item-text="name" item-value="id" label="Region"
                  dense return-object outlined></v-autocomplete>
              </div>

              <div class="col-4 pr-0">
                <v-text-field outlined v-model="yuAdress" label="Kocha" required dense></v-text-field>
              </div>
            </div>

            <div class="d-flex">
              <div class="col-4 pl-0">
                <v-text-field v-model="adress" outlined label="Qo'shimcha manzil" required dense></v-text-field>
              </div>
              <div class="col-4">
                <v-select :items="returnOwnership" v-model="typeOfProperty" item-text="name" item-value="id"
                  label="Mulchilik shakli" outlined dense></v-select>
              </div>

              <div class="col-4 pr-0">
                <v-select :error-messages="clientYurStatus" :items="returnClientStatus22" v-model="status22"
                  item-text="status" item-value="id" label="Status" dense outlined @input="$v.status22.$touch()"
                  @blur="$v.status22.$touch()"></v-select>
              </div>
            </div>
            <div>
              <v-textarea outlined dense v-model="comments" label="Izoh"></v-textarea>
            </div>
          </div>
          <div style="border: 1px solid #333; border-radius: 4px; padding: 20px">
            <h5 class="mb-10 text-dark">Kontakt malumotlari</h5>
            <h5 class="mb-5 text-dark">Direktor:</h5>
            <div>
              <v-text-field :error-messages="dirNameErrors" v-model="dirName" label="FIO" dense outlined required
                @input="$v.dirName.$touch()" @blur="$v.dirName.$touch()"></v-text-field>
            </div>
            <div>
              <label>Telefon raqam</label>
              <div class="d-flex">
                <div class="col-6 pl-0">
                  <v-text-field v-model="directorPhoneNumber" outlined dense append-icon="phone"
                    placeholder="(XX) XXX XX XX" required v-mask="'(##) ### ## ##'"></v-text-field>
                </div>
                <div class="col-6 pr-0">
                  <v-text-field v-model="directorPhoneNumber2" outlined placeholder="(XX) XXX XX XX" append-icon="phone"
                    required dense v-mask="'(##) ### ## ##'"></v-text-field>
                </div>
              </div>
            </div>
            <div class="separator separator-dashed my-5"></div>

            <h5 class="mb-5 text-dark">Mas'ul xodim:</h5>
            <div class="d-flex">
              <div class="col-6 pl-0">
                <v-text-field v-model="stuffName" dense outlined label="FIO"></v-text-field>
              </div>
              <div class="col-6 pr-0">
                <v-select :items="returnPositions" v-model="stuffPosition" item-text="name" item-value="id" dense
                  label="Lavozim" outlined></v-select>
              </div>
            </div>
            <div>
              <label>Telefon raqam</label>

              <div class="d-flex">
                <div class="col-6 pl-0">
                  <v-text-field v-model="stuffPhoneNumber" outlined append-icon="phone" placeholder="(XX) XXX XX XX" dense
                    v-mask="'(##) ### ## ##'"></v-text-field>
                </div>
                <div class="col-6 pr-0">
                  <v-text-field v-model="stuffPhoneNumber2" outlined append-icon="phone" dense
                    placeholder="(XX) XXX XX XX" v-mask="'(##) ### ## ##'"></v-text-field>
                </div>
              </div>
            </div>
          </div>
          <div class="separator separator-dashed my-5"></div>
        </div>

        <div class="d-flex justify-content-between pt-10">
          <div class="card-toolbar">
            <a v-on:click="submit" class="btn btn-primary font-weight-bolder">
              <span
                class="svg-icon svg-icon-white"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Send.svg--><svg
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                  viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                      fill="#000000" />
                  </g>
                </svg><!--end::Svg Icon--></span>

              Yuborish</a>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
// import { required, numeric, minLength, sameAs } from "vuelidate/lib/validators";
import { validationMixin } from 'vuelidate'
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { isNumberKey } from '@/utils'
import { mapActions } from 'vuex'

export default {
  mixins: [validationMixin],
  name: 'client_red',
  data() {
    return {
      code: '998',
      modelfield: '',
      directorPhoneNumber: '',
      directorPhoneNumber2: '',
      username: '',
      stuffName: '',
      stuffPosition: '',
      clientName: '',
      stuffPhoneNumber: '',
      stuffPhoneNumber2: '',
      mfo: '',
      oblast: '',
      region: '',
      status11: '',
      status22: '',
      yuAdress: '',
      typeOfProperty: '',
      adress: '',
      inn: '',
      dirName: '',
      ndsNumber: '',
      oked: '',
      comments: '',
      account: '',
      bankAccountList: [],
      bankDetailsList: [],
      clientType: [
        { name: 'Jismoniy shaxs', value: 'JI' },
        { name: 'Yuridik shaxs', value: 'YU' }
      ],
      selected_opt: '',
      passportNumber: '',
      JI: false,
      YU: false
    }
  },
  validations() {
    if (this.JI == true) {
      return {
        status11: {
          required
        },
        account: {
          // numeric
        },
        username: {
          // required
        },
        clientName: {
          required
        },
        oblast: {
          required
        },
        region: {
          required
        },
        passportNumber: {
          required
        },
        mfo: {
          // required
        }
      }
    } else {
      return {
        status22: {
          required
        },
        account: {
          required
        },
        dirName: {
          required
        },
        clientName: {
          required
        },
        inn: {
          required
        },
        oblast: {
          required
        },
        region: {
          required
        },
        mfo: {
          required
        }
      }
    }
  },

  beforeCreate() {
    // this.$store.dispatch("getPositions");
    this.$store.dispatch('getClientDetails', this.$route.params.id)
    this.$store.dispatch('getPositions')
    this.$store.dispatch('getAllMfoBank')
    this.$store.dispatch('getClientStatus')
    this.$store.dispatch('getRegions')
    this.$store.dispatch('getOwnership')
    this.$store.dispatch('getAllGroups')
  },
  watch: {
    getOnlyDetails(val) {
      console.log(val)
      this.selected_opt = val.juridical_type
      this.ddd(this.selected_opt)
      this.account = val.bank_account1
      if (this.JI) {
        this.status11 = val.status.id
        this.clientName = val.full_name
        this.oblast = val.adress_oblast.id
        this.region = val.adress_region.id
        this.passportNumber = val.passport_number
        this.adress = val.pochta_address
        this.comments = val.comments
      } else {
        this.status22 = val.status.id
        this.dirName = val.director_name
        this.dirName = val.director_name
        this.clientName = val.full_name
        this.oblast = val.adress_oblast.id
        this.region = val.adress_region.id
        this.inn = val.inn
        this.mfo = val.bank_code1
        this.oked = val.oked
        this.ndsNumber = val.code_nds
        this.bankNumber = val.code_nds
        this.yuAdress = val.yur_address
        this.adress = val.pochta_address
        this.typeOfProperty = val.ownership.id
        this.comments = val.comments
        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(val.director_phone1)) {
          this.directorPhoneNumber = val.director_phone1
            .replace(/[ ()]/g, '')
            .substring(3)
        } else this.directorPhoneNumber = val.director_phone1
        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(val.director_phone2)) {
          this.directorPhoneNumber2 = val.director_phone2
            .replace(/[ ()]/g, '')
            .substring(3)
        }
        this.stuffName = val.contact_personal_name
        this.stuffPosition = val.contact_personal_position
        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(val.contact_personal_phone1)) {
          this.stuffPhoneNumber = val.contact_personal_phone1
            .replace(/[ ()]/g, '')
            .substring(3)
        }
        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(val.contact_personal_phone2)) {
          this.stuffPhoneNumber2 = val.contact_personal_phone2
            .replace(/[ ()]/g, '')
            .substring(3)
        }
      }
    },
    clientName(val) {
      const str = val.replace(
        new RegExp('(?:\\b|_)([a-z])', 'g'),
        function ($1) {
          return $1.toUpperCase()
        }
      )
      this.clientName = str
    },
    username: function (val) {
      this.username = val.toLowerCase()
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.username))
        this.username = this.username.replace(/\s/g, '')
    },
    dirName(val) {
      const str = val.replace(
        new RegExp('(?:\\b|_)([a-z])', 'g'),
        function ($1) {
          return $1.toUpperCase()
        }
      )
      this.dirName = str
    },
    stuffName(val) {
      const str = val.replace(
        new RegExp('(?:\\b|_)([a-z])', 'g'),
        function ($1) {
          return $1.toUpperCase()
        }
      )
      this.stuffName = str
    }
  },
  computed: {
    getOnlyDetails() {
      return this.$store.state.requests.clientDetails
    },
    allGroups() {
      return this.$store.state.requests.allGroups.find(
        (x) => x.name === 'client'
      )
    },
    getMfo() {
      return this.$store.state.requests.allMfoBank
    },
    nameErrors() {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    dirNameErrors() {
      const errors = []
      if (!this.$v.dirName.$dirty) return errors
      !this.$v.dirName.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    clientNameErrors() {
      const errors = []
      if (!this.$v.clientName.$dirty) return errors
      !this.$v.clientName.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },

    clientYurInn() {
      const errors = []
      if (!this.$v.inn.$dirty) return errors
      !this.$v.inn.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    mfoClient() {
      const errors = []
      if (!this.$v.mfo.$dirty) return errors
      !this.$v.mfo.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    bankNumber() {
      const errors = []
      if (!this.$v.account.$dirty) return errors
      !this.$v.account.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    oblastErrors() {
      const errors = []
      if (!this.$v.oblast.$dirty) return errors
      !this.$v.oblast.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    clientPassportNumber() {
      const errors = []
      if (!this.$v.passportNumber.$dirty) return errors
      !this.$v.passportNumber.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    clientJisStatus() {
      const errors = []
      if (!this.$v.status11.$dirty) return errors
      !this.$v.status11.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    clientYurStatus() {
      const errors = []
      if (!this.$v.status22.$dirty) return errors
      !this.$v.status22.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    regionErrors() {
      const errors = []
      if (!this.$v.region.$dirty) return errors
      !this.$v.region.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    returnPositions() {
      return this.$store.state.requests.positions.results
    },
    returnOwnership() {
      return this.$store.state.requests.ownership
    },
    returnClientStatus11() {
      const data = this.$store.state.requests.clientStatus
      return data
    },
    returnClientStatus22() {
      const data22 = this.$store.state.requests.clientStatus
      return data22
    },
    returnOblast() {
      return this.$store.state.requests.regions
    },
    returnRegions() {
      let data = ''
      if (this.oblast !== null) {
        if (!this.oblast.id) {
          this.$store.state.requests.regions.forEach((item) => {
            if (item.id == this.oblast) {
              data = item.oblast_region
            }
          })
        } else {
          this.$store.state.requests.regions.forEach((item) => {
            if (item.id == this.oblast.id) {
              data = item.oblast_region
            }
          })
        }
      }
      return data
    }
  },
  methods: {
    isNumberKey,

    ...mapActions(['getMfoBankByNumber']),

    // getMfoDebounce(mfoCode) {
    //   this.getMfoBankByNumber(mfoId)
    // },

    ddd(value) {
      if (value == 'JI') {
        this.JI = true
        this.YU = false
      } else if (value == 'YU') {
        this.JI = false
        this.YU = true
      }
    },
    setUsername(value) {
      this.username = value
      this.$v.username.$touch()
    },
    setBankNum(value) {
      this.account = value
      this.$v.account.$touch()
    },
    submit(e) {
      e.preventDefault()
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Validatsiyadan o'tmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'

        const data = {
          ownership: this.typeOfProperty,
          adress_oblast: this.oblast.id,
          adress_region: this.region.id,
          code_nds: this.ndsNumber.split(' ').join(''),
          passport_number: this.passportNumber,
          juridical_type: this.selected_opt,
          full_name: this.clientName,
          inn: this.inn.split(' ').join(''),
          oked: this.oked,
          yur_address: this.yuAdress,

          comments: this.comments,
          pochta_address: this.adress,
          bank_code1: this.mfo,
          bank_account1: this.account.split(' ').join('')
        }
        if (this.directorPhoneNumber !== '') {
          data.director_phone1 = this.code + ' ' + this.directorPhoneNumber
        }
        if (this.directorPhoneNumber2 !== '') {
          data.director_phone2 = this.code + ' ' + this.directorPhoneNumber2
        }
        if (this.stuffPhoneNumber !== '') {
          data.contact_personal_phone1 = this.code + ' ' + this.stuffPhoneNumber
        }
        if (this.stuffPhoneNumber2 !== '') {
          data.contact_personal_phone2 =
            this.code + ' ' + this.stuffPhoneNumber2
        }
        if (this.JI == true) {
          delete data.director_name
          delete data.contact_personal_position
          delete data.contact_personal_name
          data.status = this.status11
        } else if (this.YU == true) {
          data.director_name = this.dirName
          data.contact_personal_position = this.stuffPosition
          data.contact_personal_name = this.stuffName
          data.status = this.status22
        }

        this.$store.dispatch('updateClient', {
          data: data,
          id: this.$route.params.id
        })
        this.$v.$reset()
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Mijozlar' },
      { title: "Yangi mijoz ro'yxatga olish" }
    ])
  }
}
</script>
<style scoped>
.my-input /deep/ input {
  text-transform: lowercase !important;
}

.error {
  color: red;
  display: none;
}

.form-group--error {
  display: block;
}

.v-application .error {
  background-color: inherit !important;
}
</style>
